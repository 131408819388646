import Cookies from 'js-cookie'
import jwt_decode from 'jwt-decode'

const getIdData = (): { [key: string]: string } | null => {
  const cookieName = 'partnerLinkIdToken'
  const idToken = Cookies.get(cookieName)
  if (idToken) {
    const decodedId: { [key: string]: string } = jwt_decode(idToken)
    return decodedId
  }
  return null
}

const formatDOB = (dob: string): Date => {
  const [year, month, day] = dob.split('-').map((unit) => Number(unit))
  const formattedDOB = new Date(year, month - 1, day)
  return formattedDOB
}

export const isMatchingId = (profileEmail: string | undefined) => {
  const idData = getIdData()
  if (idData && profileEmail) {
    return profileEmail === idData.name
  }
  return false
}

export const getPrepopulatedFields = () => {
  const idData = getIdData()
  if (idData) {
    const { given_name, family_name, birthdate } = idData
    const fields = {
      firstName: given_name || '',
      lastName: family_name || '',
      dateOfBirth: birthdate ? formatDOB(birthdate) : null
    }
    return fields
  }
  return null
}
